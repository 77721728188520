import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  ButtonGroup,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  OverlayTrigger,
  ToggleButton,
  Tooltip,
} from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';
import { ProjectsStateContext, reducerActionType } from './models';
import { useProjectService } from '../../services/ProjectsService';
import { notify } from '../../components/Toast/Toast';
import * as Yup from 'yup';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { Asterisk, QuestionCircleFill } from 'react-bootstrap-icons';
import {
  filterCompanyList,
  filterGamepackList,
  gamepackAppkeyList,
  makeProjectsFilterField,
} from './_utils';
import { DropdownComponent } from '../../components/Dropdown';
import { WarningColor } from '../../services/_utils';
import { SearchInputComponent } from '../../components/SearchInput';
import styled from 'styled-components';

export const AddNewProjectModal: React.FC = () => {
  const { state, dispatch } = useContext(ProjectsStateContext);

  const { loading, error, addNewProject } = useProjectService();
  const [searchGamepack, setSearchGamepack] = useState('');
  const [searchCompany, setSearchCompany] = useState('');

  useEffect(() => {
    error && notify('error', error);
  }, [error]);

  const onSubmit = (values: FormikValues) => {
    let tempPlatform = '';
    let isAmazon = false;
    switch (values.Platform) {
      case 'iOS':
        tempPlatform = 'i';
        break;
      case 'Android':
        tempPlatform = 'a';
        break;
      case 'Web':
        tempPlatform = 'w';
        break;
      case 'Amazon':
        tempPlatform = 'a';
        isAmazon = true;
        break;
    }

    const request = {
      name: values.Name,
      alias: values.Alias,
      platform: tempPlatform,
      isAmazon: isAmazon,
      bundle: values.Bundle,
      company: values.Company,
      gamepack: values.Gamepack,
    };
    addNewProject(request).then((resp) => {
      dispatch({
        type: reducerActionType.SET_PROJECTS,
        payload: makeProjectsFilterField(resp.projects),
      });
      dispatch({
        type: reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL,
        payload: false,
      });
      notify('success', `${resp.Name} has been added`);
    });
  };

  const handleClose = () => {
    dispatch({
      type: reducerActionType.SET_SHOW_ADD_NEW_PROJECT_MODAL,
      payload: false,
    });
  };

  const schema = useMemo(() => {
    return Yup.object().shape({
      Name: Yup.string().required(),
      Alias: Yup.string()
        .required()
        .min(6)
        .max(6)
        .test('regex', 'Use only lowercase letters and numbers', (value) => {
          if (value) {
            return /^[a-z\d]+$/.test(value);
          }
          return false;
        }),
      Platform: Yup.string()
        .required()
        .oneOf(
          ['iOS', 'Android', 'Web', 'Amazon'],
          'Must be iOS, Android or Web'
        ),
      Bundle: Yup.string()
        .required()
        .test(
          'regex',
          'Should looks like com.say.games/com.say.games.test',
          (value) => {
            if (value) {
              return /^[a-z\d]+(\.[a-z\d]+){2,3}$/.test(value);
            }
            return false;
          }
        ),
      Company: Yup.string()
        .required()
        .oneOf(state.companies, 'Must be one of companies'),
      Gamepack: Yup.string()
        .required()
        .oneOf(
          state.gamepackList.map((gp) => gp.Key),
          'Must be one of gamepack'
        ),
    });
  }, [state.companies]);

  const isAppAlreadySetInGamepack = (values: FormikValues) => {
    let gp = state.gamepackList.find((gp) => gp.Key === values.Gamepack);
    if (!gp) return false;
    switch (values.Platform) {
      case 'Android':
        if (values.Name.toLowerCase().includes('china')) {
          return gp.ChinaAndroidAppKey !== '' ? 'Android China' : '';
        }
        return gp.AndroidAppKey !== '' ? 'Android' : '';

      case 'iOS':
        if (values.Name.toLowerCase().includes('china')) {
          return gp.ChinaIosAppKey !== '' ? 'iOS China' : '';
        }
        return gp.IosAppKey !== '' ? 'iOS' : '';

      case 'Web':
        if (values.Name.toLowerCase().includes('crazygames')) {
          return gp.CrazyGamesAppKey !== '' ? 'Web CrazyGames' : '';
        }
        if (values.Name.toLowerCase().includes('poki')) {
          return gp.PokiAppKey !== '' ? 'Web Poki' : '';
        }
        if (values.Name.toLowerCase().includes('youtube')) {
          return gp.YoutubeAppKey !== '' ? 'Web Youtube' : '';
        }
        if (values.Name.toLowerCase().includes('saystore')) {
          return gp.SayStoreAppKey !== '' ? 'Web Saystore' : '';
        }
        return gp.WebAppKey !== '' ? 'Web' : '';

      case 'Amazon':
        return gp.AmazonAppKey !== '' ? 'Amazon' : '';

      default:
        if (values.Name.toLowerCase().includes('steam')) {
          return gp.SteamAppKey !== '' ? 'Steam' : '';
        }
        if (values.Name.toLowerCase().includes('huawei')) {
          return gp.HuaweiAppKey !== '' ? 'Huawei' : '';
        }
        if (values.Name.toLowerCase().includes('xiaomi')) {
          return gp.XiaomiAppKey !== '' ? 'Xiaomi' : '';
        }
        return '';
    }
  };

  const initialState = useMemo(() => {
    return {
      Name: '',
      Alias: '',
      Platform: 'Android',
      Bundle: '',
      Company: state.companies[0],
      Gamepack: '',
    };
  }, [state.companies]);

  return (
    <Modal
      show={state.showAddNewProjectModal}
      backdrop={'static'}
      onHide={handleClose}
      data-testid="mdlProjectListAddNewGame"
    >
      <ModalHeader closeButton>
        <ModalTitle>Add New Game</ModalTitle>
      </ModalHeader>
      <Formik
        validationSchema={schema}
        initialValues={initialState}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit} autoComplete="off">
            <ModalBody>
              <FormGroup className="position-relative mb-3">
                <FormLabel>
                  Project Name
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalAddNewGameProjectName"
                  name="Name"
                  value={values.Name}
                  onChange={handleChange}
                  isInvalid={!!errors.Name}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Name}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>
                  Alias
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalAddNewGameAlias"
                  name="Alias"
                  value={values.Alias}
                  onChange={handleChange}
                  isInvalid={!!errors.Alias}
                  placeholder="Must be 6 characters"
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Alias}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>Platform</FormLabel>
                <ButtonGroup className="d-flex flex-grow-1">
                  {['Android', 'iOS', 'Web', 'Amazon'].map((os) => (
                    <ToggleButton
                      data-testid={`rdoProjectListModalAddNewGamePlatform${os}`}
                      key={os}
                      id={`radio-${os}`}
                      type="radio"
                      name="Platform"
                      variant={
                        values.Platform === os ? 'primary' : 'outline-primary'
                      }
                      checked={values.Platform === os}
                      value={os}
                      onChange={handleChange}
                    >
                      {os}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                <p className="text-muted mt-1">Auto setup CY, Adjust, Max.</p>
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Platform}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>
                  Bundle
                  <Asterisk
                    width={6}
                    height={6}
                    color="red"
                    className="align-text-top"
                  />
                </FormLabel>
                <FormControl
                  data-testid="txtProjectListModalAddNewGameBundle"
                  name="Bundle"
                  placeholder={
                    values.Platform === 'Web'
                      ? 'web.some.game.web_store_name'
                      : ''
                  }
                  value={values.Bundle}
                  onChange={handleChange}
                  isInvalid={!!errors.Bundle}
                />
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Bundle}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative mb-3">
                <FormLabel>Company</FormLabel>
                <DropdownComponent
                  datatestid="ddlProjectListModalAddNewGameCompany"
                  autoClose={true}
                  title={values.Company}
                  onSelect={(e: SyntheticEvent<HTMLDivElement>) =>
                    setFieldValue('Company', e.toString())
                  }
                >
                  <div className="mb-2 mx-2">
                    <SearchInputComponent
                      setValue={setSearchCompany}
                      datatestid={`txtSearchCompany`}
                    />
                  </div>
                  {filterCompanyList(searchCompany, state.companies).map(
                    (v) => (
                      <div key={v}>
                        <DropdownItem
                          eventKey={v}
                          active={values.Company === v}
                        >
                          {v}
                        </DropdownItem>
                      </div>
                    )
                  )}
                </DropdownComponent>
                <FormControl.Feedback type="invalid" tooltip>
                  {errors.Company}
                </FormControl.Feedback>
              </FormGroup>
              <FormGroup className="position-relative">
                <FormLabel>
                  Gamepack
                  <OverlayTrigger
                    overlay={<Tooltip>if already exists</Tooltip>}
                  >
                    <QuestionCircleFill
                      size={16}
                      color={WarningColor}
                      className="ms-2"
                    />
                  </OverlayTrigger>
                </FormLabel>
                <DropdownComponent
                  datatestid="ddlProjectListModalAddNewGameGamepack"
                  autoClose={true}
                  title={values.Gamepack}
                  onSelect={(e: SyntheticEvent<HTMLDivElement>) =>
                    setFieldValue('Gamepack', e.toString())
                  }
                >
                  <div className="mb-2 mx-2">
                    <SearchInputComponent
                      setValue={setSearchGamepack}
                      datatestid={`txtSearchGamepack`}
                    />
                  </div>
                  {filterGamepackList(searchGamepack, state.gamepackList).map(
                    (v) => (
                      <div key={v.Key}>
                        <DropdownItem
                          eventKey={v.Key}
                          active={values.Gamepack === v.Key}
                        >
                          <span>
                            {v.Title} -{' '}
                            <span className="text-muted">{v.Key}</span>
                          </span>
                        </DropdownItem>
                        <div className="text-muted ms-4">
                          {gamepackAppkeyList(v).join(', ')}
                        </div>
                      </div>
                    )
                  )}
                </DropdownComponent>
                {!!isAppAlreadySetInGamepack(values) && (
                  <GamepackAlarm>
                    <span>Gamepack already have key for</span>
                    <code className="mx-2">
                      {isAppAlreadySetInGamepack(values)}.
                    </code>
                    <span>It will be overwritten!!!</span>
                  </GamepackAlarm>
                )}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <>
                <Button
                  data-testid="btnProjectListModalAddNewGameClose"
                  variant="secondary"
                  disabled={loading}
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  data-testid="btnProjectListModalAddNewGameAdd"
                  className="float-end"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : 'Add'}
                </Button>
              </>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const GamepackAlarm = styled.div`
  margin: 0.5rem 0;
  width: 100%;
  background-color: ${WarningColor};
  padding: 0.75rem 0.5rem;
  font-weight: 350;
  border-radius: 10px;
`;
